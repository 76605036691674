import React from 'react';
import MaterialTable from 'material-table';
import {forwardRef} from 'react';
import localization from '../../common/MaterialTableLocalization';
import {
    ArrowUpward, Check,
    ChevronLeft,
    ChevronRight, Clear,
    DeleteOutline,
    FirstPage,
    LastPage,
} from "@material-ui/icons";
import {withStyles} from "@material-ui/core/styles";

const tableIcons = {
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
};


const useStyles = () => {
    return ({
        tooltip: {
            fontSize: "20px",
            maxWidth: '1000px'
        },
    })
};

const AdminOrderItemsTable = ({
                                  t,
                                  items,
                                  selectOrderItem,
                                  deleteAdminOrderItem,
                              }) => {

    const columns = [
        {title: t('NAME'), field: 'name', sortable: true, defaultSort: 'asc'},
        {title: t('GTIN'), field: 'gtin', sorting: true},
        {title: t('ARTICLE_NUMBER'), field: 'article_number', sorting: true},
        {title: t('NET_PRICE'), field: 'net_price', type: 'numeric', sorting: true},
        {title: t('QUANTITY'), field: 'quantity', type: 'numeric', sorting: true},
        {title: t('CURRENCY'), field: 'currency', sorting: true},
        {title: t('DELIVERY_TIME'), field: 'delivery_time', sorting: true},
    ]

    return (
        <div>
            <MaterialTable
                icons={tableIcons}
                title={t('ORDER_ITEMS')}
                columns={columns.map(column => ({
                    ...column,
                    cellStyle: {textAlign: 'center', ...column.cellStyle},
                    headerStyle: {textAlign: 'center', ...column.headerStyle}
                }))}
                data={items}
                options={{
                    search: false,
                    sorting: true,
                    rowStyle: {
                        backgroundColor: 'palette.text.disabled'
                    },
                    headerStyle: {
                        backgroundColor: 'palette.text.disabled'
                    },
                }}
                localization={localization(t)}
                editable={{
                    onRowDelete: orderItem => new Promise((resolve) => {
                        deleteAdminOrderItem(orderItem.id);
                        resolve();
                    }),
                }}
                onRowClick={((event, rowData) => {
                        selectOrderItem(rowData)
                    }
                )}
            />
        </div>
    )
}


export default withStyles(useStyles)(AdminOrderItemsTable);