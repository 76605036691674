import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Box, Button, Grid, Paper, TextField, MenuItem} from '@material-ui/core';
import {measurementUnitCodes, supportedCurrencies} from "../../../utils/constants";

const useStyles = theme => {
    return ({
        buttonsContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: "10px",
        },
        paper: {
            marginTop: theme.spacing(3),
            paddingRight: theme.spacing(1),
            width: '100%',
            marginBottom: theme.spacing(2),
            flexGrow: 1
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(0),
            width: '100%',
        },
    })
};

const AdminOrderItemForm = ({
                                orderItemFormData,
                                updateOrderItemFormData,
                                saveOrderItem,
                                clearForm,
                                classes,
                                suppliers,
                                t
                            }) => {

    const handleChange = event => {
        const {name, value} = event.target;
        updateOrderItemFormData({[name]: value || null});
    };

    return (
        <div>
            <Paper>
                <Grid container spacing={1} className={classes.paper}>
                    <Grid item xs={6}>
                        <TextField className={classes.textField}
                                   label={t('NAME')}
                                   value={orderItemFormData.name}
                                   variant="outlined"
                                   margin="dense"
                                   name="name"
                                   onChange={handleChange}
                                   InputProps={{
                                       readOnly: false,
                                   }}
                                   InputLabelProps={{
                                       shrink: true,
                                       required: true
                                   }}
                        />
                        <TextField className={classes.textField}
                                   label={'GTIN'}
                                   value={orderItemFormData.gtin}
                                   variant="outlined"
                                   margin="dense"
                                   name="gtin"
                                   onChange={handleChange}
                                   inputProps={{maxLength: 13}}
                                   InputProps={{
                                       readOnly: false,
                                   }}
                                   InputLabelProps={{
                                       shrink: true,
                                       required: false
                                   }}
                        />
                        <TextField className={classes.textField}
                                   label={t('ARTICLE_NUMBER')}
                                   value={orderItemFormData.article_number}
                                   variant="outlined"
                                   margin="dense"
                                   name="article_number"
                                   onChange={handleChange}
                                   inputProps={{maxLength: 20}}
                                   InputProps={{
                                       readOnly: false,
                                   }}
                                   InputLabelProps={{
                                       shrink: true,
                                   }}
                        />
                        <TextField className={classes.textField}
                                   label={t('NET_PRICE_PER_UNIT')}
                                   value={orderItemFormData.net_price}
                                   margin="dense"
                                   name="net_price"
                                   onChange={handleChange}
                                   variant="outlined"
                                   type="number"
                                   inputProps={{
                                       min: 1
                                   }}
                                   InputLabelProps={{
                                       shrink: true,
                                       required: true
                                   }}
                        />
                        <TextField className={classes.textField}
                                   label={t('QUANTITY')}
                                   value={orderItemFormData.quantity}
                                   margin="dense"
                                   name="quantity"
                                   variant="outlined"
                                   type="number"
                                   onChange={handleChange}
                                   inputProps={{
                                       min: 1
                                   }}
                                   InputLabelProps={{
                                       shrink: true,
                                       required: true
                                   }}
                        />
                        <TextField
                            className={classes.textField}
                            select
                            fullWidth
                            margin="dense"
                            label={t('CURRENCY')}
                            name="currency"
                            value={orderItemFormData.currency}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                                required: true
                            }}
                            SelectProps={{
                                displayEmpty: true,
                                onChange: handleChange,
                            }}
                        >
                            {supportedCurrencies.map((item) => (
                                <MenuItem key={item} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            className={classes.textField}
                            select
                            fullWidth
                            defaultValue=""
                            value={orderItemFormData.supplier_gln}
                            margin="dense"
                            label={t('SUPPLIER')}
                            name="supplier_gln"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                                required: true
                            }}
                            SelectProps={{
                                displayEmpty: true,
                                onChange: handleChange,
                            }}
                        >
                            {suppliers.map((item) => (
                                <MenuItem key={item.id} value={item.gln}>
                                    {item.company_name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField className={classes.textField}
                                   label={t('DELIVERY_TIME')}
                                   value={orderItemFormData.delivery_time}
                                   margin="dense"
                                   name="delivery_time"
                                   variant="outlined"
                                   type="number"
                                   onChange={handleChange}
                                   inputProps={{
                                       min: 1
                                   }}
                                   InputLabelProps={{
                                       shrink: true,
                                       required: true
                                   }}
                        />
                        <TextField
                            className={classes.textField}
                            select
                            fullWidth
                            margin="dense"
                            label={t('MEASUREMENT_UNIT_CODE')}
                            name="measurement_unit_code"
                            value={orderItemFormData.measurement_unit_code}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                                required: true
                            }}
                            SelectProps={{
                                displayEmpty: true,
                                onChange: handleChange,
                            }}
                        >
                            {measurementUnitCodes.map((item) => (
                                <MenuItem key={item} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField className={classes.textField}
                                   label={t('NOTE')}
                                   value={orderItemFormData.note}
                                   margin="dense"
                                   name="note"
                                   variant="outlined"
                                   onChange={handleChange}
                                   InputLabelProps={{
                                       shrink: true,
                                   }}
                        />
                        <TextField className={classes.textField}
                                   label={'DESCRIPTION'}
                                   value={orderItemFormData.description}
                                   multiline
                                   rows={4}
                                   variant="outlined"
                                   margin="dense"
                                   name="description"
                                   onChange={handleChange}
                                   InputLabelProps={{
                                       shrink: true,
                                   }}
                        />
                    </Grid>
                </Grid>
                <Box className={classes.buttonsContainer}>
                    {orderItemFormData.id &&
                        <Button variant="contained" color="primary"
                                style={{marginBottom: "10px", marginRight: "10px"}}
                                onClick={() => clearForm()}>
                            {t('CLEAR')}
                        </Button>
                    }
                    <Button variant="contained" color="primary" style={{marginBottom: "10px"}}
                            onClick={saveOrderItem}>
                        {t('SAVE')}
                    </Button>
                </Box>
            </Paper>
        </div>
    )
}

export default withStyles(useStyles)(AdminOrderItemForm)