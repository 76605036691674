import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withStyles} from "@material-ui/core/styles";
import {Container} from '@material-ui/core';
import MaterialTable from 'material-table';
import {forwardRef} from 'react';
import {withTranslation} from "react-i18next";
import localization from '../common/MaterialTableLocalization';
import EnhancedTable from './TableProducts';


import {
    AddBox,
    ArrowUpward,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    Edit,
    FilterList,
    FirstPage,
    LastPage,
    Remove,
    SaveAlt,
    Search,
    ViewColumn
} from "@material-ui/icons";

import {getTradeItems2ba} from "../../actions/esearch";
import {PAGE_SIZE} from "../../utils/constants";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};

const useStyles = theme => {
    return ({
        paper: {
            marginTop: theme.spacing(3),
            width: '100%',
            overflowX: 'auto',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 650,
        },
        button: {
            margin: theme.spacing(1),
        },
        displayNone: {
            display: 'none'
        },
        deepLink: {
            display: 'table-cell',
            textDecoration: 'none',
            color: 'inherit'
        }
    })
};

export function createCustomImage(gln, product_code) {
    return `https://api.2ba.nl/1/json/Thumbnail/Product?gln=${gln}&productcode=${product_code}`;
}


class Bba extends Component {

    static propTypes = {
        search_results_2ba: PropTypes.object.isRequired,
        search_string: PropTypes.string,
        getTradeItems2ba: PropTypes.func.isRequired,
        websocket: PropTypes.object.isRequired,
        fallbackImage: PropTypes.string.isRequired
    };

    render() {
        const {classes, t, websocket, fallbackImage} = this.props;
        let table_title = 'Search results for Query string: ' + this.props.search_string + ' - Total results: ' + this.props.search_results_2ba.TotalFound;

        const replaceBrokenImage = event => {
            event.target.src = fallbackImage
        };

        return (
            <Container maxWidth={false}>
                <MaterialTable
                    icons={tableIcons}
                    title={table_title}
                    columns={[
                        {
                            title: t('IMAGE'),
                            field: '',
                            render: rowData => <img
                                src={createCustomImage(rowData.ManufacturerGLN, rowData.Productcode)}
                                style={{width: 40, borderRadius: '50%'}}
                                onError={replaceBrokenImage}
                            />
                        },
                        {title: t('PRODUCT_DESCRIPTION'), field: 'Description'},
                        {title: t('EAN_NUMBER'), field: 'GTIN'},
                        {title: t('BRAND'), field: 'Manufacture'},
                        {title: t('MODEL'), field: 'Model'},
                        {title: t('PRODUCT_DESCRIPTION'), field: 'ProductClassDescription'},
                        {title: t('SUPPLIERS'), field: 'TradeItemCount'}
                    ]}
                    data={this.props.search_results_2ba.Products}
                    options={{
                        sorting: true,
                        detailPanelType: 'single',
                        rowStyle: {
                            backgroundColor: 'palette.text.disabled'
                        },
                        headerStyle: {
                            backgroundColor: 'palette.text.disabled'
                        },
                        pageSize: PAGE_SIZE
                    }}
                    localization={localization(t)}
                    onRowClick={((event, rowData, togglePanel) => {
                            event.preventDefault();
                            togglePanel();
                            rowData.TradeItems.length === 0 && this.props.getTradeItems2ba(rowData, websocket.channel_name);
                        }
                    )}
                    detailPanel={[
                        {
                            icon: () => <AddBox className={classes.displayNone}/>,
                            disabled: true,
                            render: rowData => {
                                return (
                                    <Container maxWidth={false}>
                                        <EnhancedTable
                                            data={rowData.TradeItems}
                                            translation={t}
                                            oci={false}
                                        />
                                    </Container>
                                )
                            }
                        }
                    ]}
                />
            </Container>
        )
    }

}

const mapStateToProps = state => ({
    search_results_2ba: state.esearchReducer.search_results_2ba,
    search_string: state.esearchReducer.search_string,
    websocket: state.websocketReducer,
    fallbackImage: state.authReducer.fallbackImage,
});

export default withStyles(useStyles)(connect(mapStateToProps, {
    getTradeItems2ba,
})(withTranslation(['common'])(Bba)));