import axiosInterceptor from "../interceptor";
import {createMessage, returnErrors} from "./messages";
import {tokenConfig} from "./auth";
import {
    ADD_SUPPLIER2BA,
    CREATE_SUPPLIER_COST,
    EDIT_SUPPLIER2BA,
    GET_SUPPLIERS2BA,
    UPDATE_SUPPLIER_COST,
    UPLOAD_SUPPLIER_LOGO,
    GET_SUPPLIER_COSTS,
    GET_ALL_SUPPLIERS2BA
} from "./types";

export const getSuppliers2ba = (page, pageSize, searchQuery) => (dispatch, getState) => {
    const params = {page, page_size: pageSize, search_query: searchQuery}

    axiosInterceptor
        .get('/api/suppliers2ba/', {
            params,
            ...tokenConfig(getState)
        })
        .then(res => dispatch({
            type: GET_SUPPLIERS2BA,
            payload: res.data
        }))
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const getAllSuppliers2ba = (isEnabled = 'True', isTenderParticipant = undefined) => (dispatch, getState) => {
    const params = {show_all: true, is_enabled: isEnabled}

    if(isTenderParticipant!==undefined){
        params.is_tender_participant = isTenderParticipant
    }

    axiosInterceptor
        .get('/api/suppliers2ba/', {
            params,
            ...tokenConfig(getState)
        })
        .then(res => dispatch({
            type: GET_ALL_SUPPLIERS2BA,
            payload: res.data
        }))
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
}

export const addSupplier2ba = item => (dispatch, getState) => {
    axiosInterceptor
        .post('/api/suppliers2ba/', item, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({addSupplier2ba: 'Supplier Added'}));
            dispatch({
                type: ADD_SUPPLIER2BA,
                payload: res.data
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const editSupplier2ba = (newData, oldData) => (dispatch, getState) => {
    axiosInterceptor
        .patch(`/api/suppliers2ba/${oldData.id}/`, newData, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({editSupplier2ba: 'Supplier Edited'}));
            dispatch({
                type: EDIT_SUPPLIER2BA,
                newData: res.data,
                oldData: oldData
            });
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const deleteSupplier2ba = supplierId => (dispatch, getState) => {
    axiosInterceptor
        .delete(`/api/suppliers2ba/${supplierId}/`, tokenConfig(getState))
        .then(() => {
            dispatch(createMessage({deleteSupplier2ba: 'Supplier Deleted'}));
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const uploadSupplierLogo = data => (dispatch, getState) => {
    const config = tokenConfig(getState);
    config.headers['Content-Type'] = 'multipart/form-data';
    axiosInterceptor
        .post('/api/upload_supplier_logo', data, config)
        .then(res => {
            dispatch(createMessage({uploadSupplierLogo: 'Supplier Logo Added'}));
            dispatch({
                type: UPLOAD_SUPPLIER_LOGO,
                payload: res.data
            })
        })
};

export const getSupplierCosts = (supplierId) => (dispatch, getState) => {
    const params = {supplier_id: supplierId}

    axiosInterceptor
        .get('/api/supplier_cost/', {
            params: params,
            ...tokenConfig(getState)
        })
        .then(res => dispatch({
            type: GET_SUPPLIER_COSTS,
            payload: {
                supplierCosts: res.data,
                supplierId
            }
        }))
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const createSupplierCost = (supplierCost, supplierId) => (dispatch, getState) => {
    const data = {
        ...supplierCost,
        supplier: supplierId
    }

    axiosInterceptor
        .post(`/api/supplier_cost/`, data, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({supplierCost: 'SUPPLIER_COST_SAVED_SUCCESSFULLY'}));
            dispatch({
                type: CREATE_SUPPLIER_COST,
                payload: res.data,
            })
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        })
};

export const updateSupplierCost = (supplierCost, supplierId) => (dispatch, getState) => {
    const data = {
        ...supplierCost,
        supplier: supplierId
    }

    axiosInterceptor
        .put(`/api/supplier_cost/${supplierCost.id}/`, data, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({supplierCost: 'SUPPLIER_COST_SAVED_SUCCESSFULLY'}));
            dispatch({
                type: UPDATE_SUPPLIER_COST,
                payload: res.data,
            })
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        })
};

export const deleteSupplierCost = (supplierCostId, supplierId) => (dispatch, getState) => {
    axiosInterceptor
        .delete(`/api/supplier_cost/${supplierCostId}/`, tokenConfig(getState))
        .then(() => {
            dispatch(createMessage({deleteSupplierCost: 'SUPPLIER_COST_DELETED_SUCCESSFULLY'}));
            dispatch({
                type: DELETE_SUPPLIER_COST,
                payload: {supplierCostId, supplierId}
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const getAvailableSupplierCostTypes = (supplierId) => async (dispatch, getState) => {
    const params = {supplier_id: supplierId};
    const response = await axiosInterceptor.get('/api/supplier_cost/type', {
        params: params,
        ...tokenConfig(getState)
    });
    return response.data;
};